var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "errorContainer",
    { attrs: { error: _vm.erreur, warning: _vm.warning } },
    [
      _vm.Licenses
        ? _c(
            "b-row",
            [
              _c("b-col", { attrs: { md: "12" } }, [
                !_vm.$screen.sm
                  ? _c(
                      "h1",
                      {
                        class: [
                          `${!_vm.$screen.sm ? "main-page-title" : ""}`,
                          ,
                          { "is-pwa": _vm.$isPwa() },
                        ],
                      },
                      [
                        _vm._v(
                          "\n\t\t\t\t" + _vm._s(this.caption1) + "\n\t\t\t"
                        ),
                      ]
                    )
                  : _vm._e(),
              ]),
              _c(
                "b-col",
                { attrs: { cols: "12", xl: "12" } },
                [
                  _c(
                    "transition",
                    { attrs: { name: "slide" } },
                    [
                      _c(
                        "b-card",
                        { attrs: { "no-body": "" } },
                        [
                          _vm.$screen.sm
                            ? _c("b-card-header", [
                                _c("h4", [_vm._v(_vm._s(this.caption1))]),
                              ])
                            : _vm._e(),
                          _vm.newLic && _vm.newLic.user
                            ? _c(
                                "b-card-body",
                                [
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "6" } },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          11,
                                                          "License:"
                                                        ),
                                                        "label-for": "licName",
                                                        "label-cols": 3,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          id: "licName",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic.license,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic,
                                                              "license",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.license",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          12,
                                                          "Name:"
                                                        ),
                                                        "label-for": "userName",
                                                        "label-cols": 3,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          id: "userName",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic.user
                                                              .name,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic.user,
                                                              "name",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.user.name",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          13,
                                                          "First name:"
                                                        ),
                                                        "label-for":
                                                          "userFirstName",
                                                        "label-cols": 3,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          id: "userFirstName",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic.user
                                                              .firstName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic.user,
                                                              "firstName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.user.firstName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: this.FormMSG(
                                                          80,
                                                          "Langue"
                                                        ),
                                                        "label-for":
                                                          "optionsForLanguages",
                                                        "label-cols": 3,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-select", {
                                                        attrs: {
                                                          options:
                                                            _vm.optionsForLanguages,
                                                          id: "optionsForLanguages",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic
                                                              .userLanguage,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic,
                                                              "userLanguage",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.userLanguage",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          14,
                                                          "Email:"
                                                        ),
                                                        "label-for": "email",
                                                        "label-cols": 3,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          id: "email",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic.user
                                                              .email,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic.user,
                                                              "email",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.user.email",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        label: _vm.FormMSG(
                                                          15,
                                                          "Company:"
                                                        ),
                                                        "label-for":
                                                          "companyName",
                                                        "label-cols": 3,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-input", {
                                                        attrs: {
                                                          type: "text",
                                                          id: "companyName",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic
                                                              .companyName,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic,
                                                              "companyName",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.companyName",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c("SupplierSelector", {
                                                    ref: "refSupplier",
                                                    attrs: {
                                                      title: `${
                                                        !_vm.isSupplierUpdate
                                                          ? _vm.FormMSG(
                                                              250,
                                                              "Add supplier details"
                                                            )
                                                          : _vm.FormMSG(
                                                              251,
                                                              "Update supplier details"
                                                            )
                                                      }`,
                                                      label: _vm.FormMSG(
                                                        16,
                                                        "Company data"
                                                      ),
                                                      addlabel: _vm.FormMSG(
                                                        30,
                                                        "Add"
                                                      ),
                                                      "update-label":
                                                        _vm.FormMSG(
                                                          33,
                                                          "Update"
                                                        ),
                                                      placeholder: _vm.FormMSG(
                                                        31,
                                                        "Filter by name..."
                                                      ),
                                                      "supplier-id":
                                                        _vm.companyId,
                                                      type: "licenseOlder",
                                                    },
                                                    on: {
                                                      change:
                                                        _vm.handleSupplierChange,
                                                      "supplier:unselected":
                                                        _vm.onSupplierUnselected,
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                { attrs: { sm: "12" } },
                                                [
                                                  _c(
                                                    "b-form-group",
                                                    {
                                                      attrs: {
                                                        "label-cols": 0,
                                                      },
                                                    },
                                                    [
                                                      _c("b-form-textarea", {
                                                        attrs: {
                                                          id: "description",
                                                          placeholder:
                                                            _vm.FormMSG(
                                                              17,
                                                              "description..."
                                                            ),
                                                          "no-resize": true,
                                                          state:
                                                            _vm.newLic
                                                              .description
                                                              .length < 4000,
                                                          rows: "6",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.newLic
                                                              .description,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.newLic,
                                                              "description",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "newLic.description",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { sm: "2" } }),
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "3" } },
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c(
                                                    "b-button",
                                                    {
                                                      staticClass: "btn1",
                                                      attrs: {
                                                        variant: "primary",
                                                        size: "lg",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.goBack()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.FormMSG(
                                                            20,
                                                            "Back to licenses"
                                                          )
                                                        ) + " "
                                                      ),
                                                      _c("i", {
                                                        staticClass:
                                                          "icon-action-undo",
                                                      }),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("b-button", {
                                                    staticClass: "btn1",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "lg",
                                                      disabled:
                                                        this.disableSave,
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.FormMSG(
                                                          21,
                                                          "Save modifications"
                                                        )
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.curLicUpd()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-form-group",
                                                [
                                                  _c("b-button", {
                                                    staticClass: "btn1",
                                                    attrs: {
                                                      variant: "primary",
                                                      size: "lg",
                                                      disabled:
                                                        this.curLic.id == 0,
                                                    },
                                                    domProps: {
                                                      innerHTML: _vm._s(
                                                        _vm.FormMSG(
                                                          32,
                                                          "New project"
                                                        )
                                                      ),
                                                    },
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.newProject()
                                                      },
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-row",
                                    [
                                      _c(
                                        "b-col",
                                        { attrs: { sm: "6" } },
                                        [
                                          _c(
                                            "b-form-group",
                                            [
                                              _c("H5", [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      this.createdByString
                                                    ) +
                                                    " "
                                                ),
                                              ]),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c("b-col", { attrs: { sm: "3" } }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "b-card",
                                    { attrs: { "no-body": "" } },
                                    [
                                      _c("b-card-header", [
                                        _c("h4", [
                                          _vm._v(_vm._s(this.caption4)),
                                        ]),
                                      ]),
                                      _c(
                                        "b-card-body",
                                        [
                                          _c(
                                            "b-row",
                                            [
                                              _c(
                                                "b-col",
                                                {
                                                  attrs: {
                                                    cols: "12",
                                                    xl: "12",
                                                  },
                                                },
                                                [
                                                  _c("loading", {
                                                    attrs: {
                                                      active: _vm.isLoading,
                                                      "is-full-page": true,
                                                    },
                                                    on: {
                                                      "update:active":
                                                        function ($event) {
                                                          _vm.isLoading = $event
                                                        },
                                                    },
                                                  }),
                                                  _vm.$screen.width < 992
                                                    ? _c("CardListBuilder", {
                                                        attrs: {
                                                          items: _vm.Projects,
                                                          fields:
                                                            _vm.projectFields,
                                                        },
                                                        on: {
                                                          "row-clicked":
                                                            _vm.rowClicked,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "actions",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "primary",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.updateProject(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EDIT
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EDIT
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "danger",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.delItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .TRASH
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .TRASH
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          3978399744
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                  _vm.$screen.width >= 992
                                                    ? _c("b-table", {
                                                        ref: "myTable",
                                                        staticStyle: {
                                                          "text-align": "left",
                                                        },
                                                        attrs: {
                                                          hover: _vm.hover,
                                                          responsive: "sm",
                                                          "sticky-header": "",
                                                          selectable: "",
                                                          selectedVariant:
                                                            _vm.selectedColor,
                                                          "select-mode":
                                                            _vm.selectMode,
                                                          items: _vm.Projects,
                                                          fields:
                                                            _vm.projectFields,
                                                          "current-page":
                                                            _vm.currentPage,
                                                          filter: _vm.filter,
                                                          "per-page":
                                                            _vm.perPage,
                                                          bordered: "",
                                                          small: "",
                                                          "head-variant":
                                                            _vm.hv,
                                                        },
                                                        scopedSlots: _vm._u(
                                                          [
                                                            {
                                                              key: "cell(view)",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "primary",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.updateProject(
                                                                              data.item
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .EDIT
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .EDIT
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "cell(rem)",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "danger",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.delItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .TRASH
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .TRASH
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                            {
                                                              key: "cell(copy)",
                                                              fn: function (
                                                                data
                                                              ) {
                                                                return [
                                                                  _c(
                                                                    "b-button",
                                                                    {
                                                                      staticClass:
                                                                        "btn bg-transparent border-0",
                                                                      attrs: {
                                                                        variant:
                                                                          "primary",
                                                                        size: "sm",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.copyItem(
                                                                              data
                                                                                .item
                                                                                .id
                                                                            )
                                                                          },
                                                                      },
                                                                    },
                                                                    [
                                                                      _c(
                                                                        _vm.getLucideIcon(
                                                                          _vm
                                                                            .ICONS
                                                                            .COPY
                                                                            .name
                                                                        ),
                                                                        {
                                                                          tag: "component",
                                                                          attrs:
                                                                            {
                                                                              color:
                                                                                _vm
                                                                                  .ICONS
                                                                                  .COPY
                                                                                  .color,
                                                                              size: 20,
                                                                            },
                                                                        }
                                                                      ),
                                                                    ],
                                                                    1
                                                                  ),
                                                                ]
                                                              },
                                                            },
                                                          ],
                                                          null,
                                                          false,
                                                          2105675523
                                                        ),
                                                      })
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _c(
                                            "b-modal",
                                            {
                                              staticClass: "modal-success",
                                              attrs: {
                                                "header-class":
                                                  "header-class-modal-doc-package",
                                                title: _vm.FormMSG(
                                                  60,
                                                  "DELETE ?"
                                                ),
                                                "ok-variant": "danger",
                                                "ok-title": this.FormMSG(
                                                  66,
                                                  "Delete Project"
                                                ),
                                                "modal-class": "mui-animation",
                                                fade: false,
                                              },
                                              on: {
                                                ok: function ($event) {
                                                  return _vm.delItemConfirmed()
                                                },
                                              },
                                              model: {
                                                value: _vm.successModal,
                                                callback: function ($$v) {
                                                  _vm.successModal = $$v
                                                },
                                                expression: "successModal",
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n\t\t\t\t\t\t\t\t\t" +
                                                  _vm._s(
                                                    this.FormMSG(
                                                      61,
                                                      "Are you sure you want to delete this item?"
                                                    )
                                                  ) +
                                                  "\n\t\t\t\t\t\t\t\t"
                                              ),
                                            ]
                                          ),
                                          _c(
                                            "b-modal",
                                            {
                                              staticClass: "modal-success",
                                              attrs: {
                                                "header-class":
                                                  "header-class-modal-doc-package",
                                                title: _vm.FormMSG(
                                                  62,
                                                  "Copy Project?"
                                                ),
                                                "ok-variant": "primary",
                                                "ok-title": this.FormMSG(
                                                  67,
                                                  "Copy Project"
                                                ),
                                                "modal-class": "mui-animation",
                                                fade: false,
                                              },
                                              on: {
                                                ok: function ($event) {
                                                  return _vm.copyItemConfirmed()
                                                },
                                              },
                                              model: {
                                                value: _vm.successCopyModal,
                                                callback: function ($$v) {
                                                  _vm.successCopyModal = $$v
                                                },
                                                expression: "successCopyModal",
                                              },
                                            },
                                            [
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.copyWhat,
                                                    expression: "copyWhat",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id: "allData",
                                                  value: "AllData",
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.copyWhat,
                                                    "AllData"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.copyWhat = "AllData"
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                { attrs: { for: "allData" } },
                                                [
                                                  _vm._v(
                                                    "  " +
                                                      _vm._s(
                                                        this.FormMSG(
                                                          63,
                                                          "Copy all data"
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                              _c("br"),
                                              _c("input", {
                                                directives: [
                                                  {
                                                    name: "model",
                                                    rawName: "v-model",
                                                    value: _vm.copyWhat,
                                                    expression: "copyWhat",
                                                  },
                                                ],
                                                attrs: {
                                                  type: "radio",
                                                  id: "configData",
                                                  value: "AllConfig",
                                                },
                                                domProps: {
                                                  checked: _vm._q(
                                                    _vm.copyWhat,
                                                    "AllConfig"
                                                  ),
                                                },
                                                on: {
                                                  change: function ($event) {
                                                    _vm.copyWhat = "AllConfig"
                                                  },
                                                },
                                              }),
                                              _c(
                                                "label",
                                                {
                                                  attrs: { for: "configData" },
                                                },
                                                [
                                                  _vm._v(
                                                    "  " +
                                                      _vm._s(
                                                        this.FormMSG(
                                                          64,
                                                          "Copy configuration data only"
                                                        )
                                                      )
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }